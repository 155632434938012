import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import notify from 'notify';
import { styled } from 'styles';
import { REGEXP, SITE_URL } from 'consts';
import useAuthContext from 'hooks/useAuthContext';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import { checkInviteCode, register as registerRequest } from './api';

const phoneInputProps = {
  as: MaskedInput,
  mask: '+1 (999) 999-9999',
  type: 'tel'
};

const RegisterPage = ({ inviteCode }: { inviteCode: string; }) => {
  const { loginManually } = useAuthContext();
  const [inviteCodeError, setInviteCodeError] = useState<null | string>(null);
  const [email, setEmail] = useState<string>('');

  const { register, control, handleSubmit, errors, setError, getValues, formState } = useForm({
    mode: 'all'
  });

  console.log(errors);

  const submitForm = async values => {
    try {
      const payload = {
        brokerMlsId: values.brokerMlsId,
        password: values.password,
        name: values.name,
        phone: values.phone,
        phoneOffice: values.phoneOffice
      };
      const response = await registerRequest(payload, inviteCode);
      loginManually(response);
      navigate('/');
    } catch (err) {
      const passwordError = err.response?.params?.password?.[0];
      if (passwordError) setError('password', { type: 'manual', message: passwordError });
      else notify(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const email = await checkInviteCode(inviteCode);
        setEmail(email);
      } catch (err) {
        setInviteCodeError(err.response);
      }
    })();
  }, [inviteCode]);

  if (inviteCodeError) {
    return (
      <StyledRegisterPage className="invite-code-error">
        <h1 className="title">{inviteCodeError}</h1>
        <div className="info">
          Request a new link or <Link to="/login">sign up →</Link>
        </div>
        <Link className="about" to="/login">
          What is XchangePlus? →
        </Link>
      </StyledRegisterPage>
    );
  }

  return (
    <StyledRegisterPage>
      <h1 className="title">Complete your registration</h1>
      <div className="info">
        Have an account already? <Link to="/login">Login →</Link>
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input disabled label="Email" placeholder="Enter Email" value={email} />
        <Input
          ref={register({ required: 'Required' })}
          name="brokerMlsId"
          error={errors.brokerMlsId?.message}
          label="MLS ID"
          placeholder="Enter Broker MLS ID"
        />
        <br />
        <Input
          ref={register({
            required: 'Required',
            minLength: {
              value: 8,
              message: 'Password must be 8-32 characters long'
            },
            maxLength: {
              value: 32,
              message: 'Password must be 8-32 characters long'
            },
            pattern: {
              value: REGEXP.PASSWORD,
              message: 'Invalid password'
            }
          })}
          name="password"
          error={errors.password?.message}
          label="Password"
          placeholder="Enter Password"
          type="password"
        />
        <Input
          ref={register({
            required: 'Required',
            validate: value => value === getValues('password') || `Passwords don't match`
          })}
          name="passwordConfirm"
          error={errors.passwordConfirm?.message}
          label="Confirm Password"
          placeholder="Confirm Password"
          type="password"
        />
        <br />
        <Input
          ref={register({ required: 'Required' })}
          name="name"
          error={errors.firstName?.message}
          label="Full Name"
          placeholder="Enter Full Name"
        />
        <br />
        <Controller
          control={control}
          rules={{ validate: value => !value?.includes('_') }}
          defaultValue=""
          name="phone"
          render={({ value, ...props }) => (
            <Input
              error={Boolean(errors.phone)}
              value={value || ''}
              label="Phone"
              placeholder="Enter Phone Number"
              {...props}
              {...phoneInputProps}
            />
          )}
        />
        <Controller
          control={control}
          rules={{ validate: value => !value?.includes('_') }}
          defaultValue=""
          name="phoneOffice"
          render={({ value, ...props }) => (
            <Input
              error={Boolean(errors.phoneOffice)}
              value={value || ''}
              label="Office Phone"
              placeholder="Enter Office Phone Number"
              {...props}
              {...phoneInputProps}
            />
          )}
        />
        <br />
        <Checkbox
          ref={register({ required: 'Required' })}
          name="terms"
          className="terms"
          label={
            <>
              I have reviewed and agree to the{' '}
              <a href={`${SITE_URL}/privacy`} target="_blank" rel="noreferrer">
                Terms and Conditions
              </a>
              {' and '}
              <a href={`${SITE_URL}/privacy`} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </>
          }
        />
        <Button className="button-submit" disabled={!formState.isValid}>
          Register
        </Button>
      </form>
    </StyledRegisterPage>
  );
};

export default RegisterPage;

const StyledRegisterPage = styled.div`
  /* mobile first */

  &.invite-code-error {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 890px;

    .about {
      margin-top: auto;
    }
  }

  a {
    font-weight: bold;
    color: ${props => props.theme.colors.red};
    text-decoration: none;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .info {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 24px;
  }

  form {
    display: flex;
    flex-direction: column;

    br {
      display: none;
    }

    .input,
    .button {
      width: 100%;
    }

    .input {
      margin-bottom: 20px;
    }

    .forgot-password {
      display: inline-block;
      font-size: 14px;
      line-height: 30px;
      color: ${props => props.theme.colors.darkCementGray};
      text-decoration: none;
      margin: 4px 0 55px 0;
    }

    .terms {
      margin-bottom: 32px;
      align-items: flex-start;

      label > a {
        color: ${props => props.theme.colors.red};
        font-weight: 600;
        text-decoration: none;
      }
    }

    .button-submit {
      display: block;
      width: 100%;
      height: 40px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      margin: 0 0 30px 0;
    }

    .info {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 46px;
    }

    form {
      display: initial;

      br {
        display: initial;
      }

      .input {
        width: 300px;
        margin-right: 20px;
      }

      .terms {
        margin-top: 80px;
      }

      .button-submit {
        height: 40px;
        width: 300px;
      }
    }
  }
`;
